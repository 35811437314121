import React from 'react'
import styled from 'styled-components'
import { colors,  space } from '../../consts/style';


const Separator = styled.div `
 height:2px;
 margin-bottom:1.3rem;
 width:20%;
  background-color:${colors.yellow};
`

const Nom = styled.span `
  font-weight:400;
  color:${colors.text2};
`

const Card = styled.div `
  background-color:white;
  cursor:pointer;
  color:black;
  padding:2rem;
  border:1rem solid ${colors.green};
`


const TestimonialCard = ({author, comment}) => {

  return (
	<Card>
      <div dangerouslySetInnerHTML={{ __html: comment }} />
      <Separator/>
       <Nom>{author}</Nom>  
  </Card>

  )
}

export default TestimonialCard