import React, { Fragment, useRef } from "react"
import { graphql } from "gatsby"
import { Icon } from '@iconify/react';
import styled from "styled-components"
import _map from "lodash/map"
import { GatsbyImage } from 'gatsby-plugin-image';
import FormatDate  from '../components/formatDate'
import { Fade } from "react-awesome-reveal"
import { MdNavigateBefore, MdNavigateNext, MdDateRange } from 'react-icons/md'
import BtnPrimary from "../components/buttons/ButtonRounded"
import ContactBox from '../components/contactBox'
import Encart from '../components/encart'
import Seo from "../components/seo"
import Boop from "../components/boop"
import Link from '../components/ExtendedLink';
import { colors, mq, font } from "../consts/style"
import TestimonialList from '../components/Testimonial/testimonialList'
import {
  PageWrapper,
  PageInner,
  BgWrap,
  Spacer,
  Grid2Col,
  SectionTitle,
  Text,
} from "../components/Elements"
import Popup from '../components/popup'
import ContactPopUp from '../components/contactPopUp'
import { useModalWithData } from '../hooks/modal-hook'

const ThematiqueItem  = styled.div`
  padding-bottom:2rem;
  padding-top:2rem;
  margin-bottom:2rem;

  & h2 {
    margin:0;
    margin-bottom:2rem;
    line-height:normal;
    padding:2rem 0;
    border-top:3px solid ${colors.yellow};
    border-bottom:3px solid ${colors.yellow};
  }
`

const Thematique = ({data}) => {
  return(
    <ThematiqueItem>
    <h2>{data.titre}</h2>
    <Text dangerouslySetInnerHTML={{ __html: data.contenu}} />
    </ThematiqueItem>
  )
}


const Header = styled.div`
position:relative;
display:grid;
grid-template-columns:1fr 1fr;

justify-content:flex-end;
align-items:center;
max-height:80rem;
min-height:60rem;
overflow:hidden;
margin-bottom:6.4rem;
& > .react-awesome-reveal 
{
  height:100%;
}
/* MOBILE */  
${mq.tablet` 
 
  grid-template-columns: 1fr;
  grid-template-rows: 300px 1fr;
  max-height: 100%;
  padding-bottom: 1.6rem;
  margin-bottom:1.6rem;
`}
 
`

const Sticky = styled.div`
  top: 3.2rem;
  position: sticky;
  margin-bottom: 1.6rem;
  width: 100%;
  text-align: center;
  ${mq.tablet`
    margin-bottom: 2rem;
    position: relative;
  `}
`;
const BoxHeader = styled.div`
color:${colors.dark};
padding:3.2rem;
display:flex;
height:100%;
flex-direction:column;
justify-content:center;
background-color: ${colors.yellow};

& .entete {  
  p {
    font-size:1.6rem;
    line-height:150%;
  }
  ul {
      padding-left:1.6rem;
      margin-top:3.2rem;
      list-style:none;
      li {
        margin-bottom:.8rem;
        color:${colors.text2};
        font-size:2rem;
        line-height:150%;
        &:before {
            color:${colors.text1};
            content:"✔ ";
            margin-right:.4rem;
          }
      }
    }
}
   
`


const Title = styled.h1`
${font.pageTitle};
`

const Image = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
  z-index: -1;
  // Adjust image positioning (if image covers area with defined height) and add font-family for polyfill
  & > img {
    object-fit:cover;
    object-position: ${props => props.position || '50% 50%'} !important;
  }
`


const Service = ({ data, pageContext, location }) => {
  const {
    modalOpen,
    selected,
    setSelected,
    setModalState
    } = useModalWithData();


//console.log(data.service.seoMetaTags)

  return (
    <Fragment>
      <Seo meta={data.service.seoMetaTags}/>
      <Header> 
        <Fade direction="left" fraction="0" distance="50px" style={{height:"100%"}}>
          <Image image={data.service.image.gatsbyImageData} alt= {data.service.titre}/>
        </Fade>
       
        <BoxHeader>
          <Fade direction="right" distance="50px">
            <Title>
              {data.service.titre}
            </Title>
            <div className="entete" dangerouslySetInnerHTML={{ __html: data.service.chapeau}} />
          </Fade>
        </BoxHeader>  
      </Header>

      <PageWrapper>
          <PageInner>
            <Grid2Col>
              <Sticky>
               
                <div>
                  {data.service.encart.map(block => (
                    <div key={block.id}>
                      {block.model.apiKey === 'encart' && <Encart data={block}/>}
                    </div>
                  ))}
                </div>
               
                <ContactBox/>
              </Sticky>
              <div>
              <Text dangerouslySetInnerHTML={{ __html: data.service.contenu}}/>
              {(data.service.thematiques !== null) && 
               <>
     
               {data.service.thematiques.map(thematique => (
                    <Thematique key={thematique.id} data={thematique}/>
                  ))}
               </>
               
               
               }
              <Popup isActive={modalOpen} handleClose={()=>setModalState(false)}>
                <ContactPopUp data={selected} />
              </Popup>
              <BtnPrimary popup onClick={()=>{
                setSelected({selected})
                setModalState(true)
                }}>
                 Prendre rendez-vous
              </BtnPrimary>
              </div>
            </Grid2Col>
            
            
          
          </PageInner>  
          { (data.service.temoignages !== null) &&
              <BgWrap color={colors.yellow}>
                <PageInner>
                  <SectionTitle>Témoignages</SectionTitle>
                   
                  <TestimonialList data={data.service.temoignages}/>
                  </PageInner>
              </BgWrap>
            }   
      </PageWrapper>

      
      
    </Fragment>
  )
}

export const ServiceQuery = graphql`


  query($slug: String!, $locale: String!) {
    service: datoCmsService(slug: { eq: $slug }, locale: { eq: $locale }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      titre
      chapeau
      contenu
      image {
        gatsbyImageData(  
          placeholder: BLURRED,
          forceBlurhash: false,
          width:900 
        )
      }
      encart {
          ...on DatoCmsEncart {
            model {
              apiKey
            }
            id: originalId
            titre
            contenu
          }  
        
      }
      thematiques {
        titre
        contenu
        id
    }
      temoignages {
       
          nom
          contenu
        
      }
    }
  }
`

export default Service