import React from 'react'
import styled from 'styled-components'
import { FaPhoneSquare } from 'react-icons/fa'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { FaEnvelope } from 'react-icons/fa'
import  config  from '../config/SiteConfig'
import { colors, space, font, mq } from '../consts/style';



const Box = styled.div`
 display:flex;
 gap:.5rem;
 max-width:310px;
 flex-direction:column;
 align-items:center;
 justify-content:center;
 padding:2.8rem;
 padding-bottom:0;
 margin: 0 auto;
 margin-top:6.4rem;
 background-color: ${props => props.background};
 border-radius:.8rem;
 /*padding-left:0;*/
 & div {
  display:flex;
  flex-wrap: nowrap;
  width:100%;
  gap:1.5rem;
  text-align:left;
 }

 ${mq.tabletSmall` 
  margin-top:0;
  padding:0;
  `}
 
`

const ContactBox = ({withBackground = "transparent"}) => {
  
 

  return (
    <Box background={withBackground}>
      <div>
        <FaPhoneSquare className="icon-default text2"/>
        <span className="text-default"><a href={`tel:${config.siteTelPortable}`} className="link-effect">{config.siteTelPortableLabel}</a></span>
      </div> 
       <div>
          <FaEnvelope className="icon-default text2"/>
        <span className="text-default"><a href={`mailto:${config.siteEmail}`} className="link-effect">{config.siteEmail}</a></span>
      </div>  
      <div>
        <FaMapMarkerAlt className="icon-default text2"/>
        <p className="text-default" ><b>Atelier des Thérapies</b><br/>
        3 rue Soffrey Calignon<br/>
        38500 Voiron<br/>
        Isère, France</p>
      </div>
      <div>
       
        <FaMapMarkerAlt className="icon-default text2"/>
       
        <p className="text-default" >
        <b>Bulle de Bonheur</b><br/>
          9 rue Jacques Thibaud<br/>
          38100 Grenoble<br/>
          Isère, France</p>
        
      </div>
    </Box>
    
  )
}

export default ContactBox

