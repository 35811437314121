// import Swiper core and required modules
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import React from 'react'
import styled from 'styled-components'
import { colors,  space } from '../../consts/style';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import TestimonialCard from '../Testimonial/testimonial'
const SliderDefault = ({slides}) => {
  return (
    <Swiper
      // install Swiper modules
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      spaceBetween={50}
      slidesPerView={1.3}
      breakpoints={{
        640: {
          slidesPerView: 2.2,
          spaceBetween: 30,
        },
        
        1200: {
          slidesPerView: 3.2,
          spaceBetween: 50,
        },
      }}
      navigation
  
    >
      { slides.map((testimonial,i) => {         
                return (
                    <SwiperSlide key={i}>
                        <TestimonialCard  
                            key={i} 
                            author={testimonial.nom} 
                            comment={testimonial.contenu}              
                        />
                  </SwiperSlide>
                 )     
              }) 
            }
		
     
    </Swiper>
  );
};

export default SliderDefault