module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  siteTitle: "AL Sophro", // Navigation and Site Title
  siteTitleAlt: "Aurélie Lasherme - Sophrologue à Voiron (Isère)", // Alternative Site title for SEO
  siteUrl: 'https://alsophro.com', // Domain of your site. No trailing slash!
  siteLanguage: 'fr', // Language Tag on <html> element
  siteLogo: '/images/logo-al-sophro-manifest.png', // Used for SEO and manifest
  siteDescription: `Mobilisez votre corps et votre conscience pour redécouvrir et renforcer vos capacités de bien-être, d'adaptation et d'évolution. Basée en Isère (38) à Voiron, je vous accompagne dans la découverte et la pratique d'exercices de sophrologie en séances individuelles ou en groupe. Au fil de ces séances, vous vous réappropriez votre corps et votre esprit, vous reprenez la main sur votre vie, en vous éloignant des automatismes et des conditionnements.`,
  siteTelPortable:'+33778125728',
  siteTelPortableLabel:'07 78 12 57 28',
  siteEmail:'aurelie@alsophro.com',
  author:'Olivier Genevest',
  authorUrl:'https://oliviergenevest.info/contacts',
  publisher:'Aurélie Lasherme',
  shareImage: '/images/logo-al-sophro-manifest.png', // Open Graph Default Share Image. 1200x1200 is recommended
  //siteFBAppID: '123456789', // Facebook App ID
  facebookPageUrl:'https://www.facebook.com/ALSphrlg',
 // youtubePageUrl:'https://www.youtube.com/channel/UCPTVKvqYrKLXCJeF4Q5lRtQ',
  //userTwitter: '@emma', // Twitter Username
  // og: Meta Tags
  ogSiteName: 'Aurélie Lasherme - Sophrologue', // Facebook Site Name
  ogLanguage: 'fr_FR',
  googleAnalyticsID: '',
  
  // Date format used in your project header
  // More information here: https://date-fns.org/v1.29.0/docs/format
  dateFormat: "DD.MM.YYYY",
 

  // Theme, Manifest and Progress color
  themeColor: '#8888F1',
  backgroundColor: '#1F2229',

};
