import React from 'react'
/*import { animated, config, useSpring } from 'react-spring'*/
import TestimonialCard from './testimonial'
import styled from 'styled-components'
import SliderDefault from '../slider/SliderDefault'
const TestimonialList = ({data}) => {
  
/*  const pageAnimation = useSpring({
    config: config.slow,
    from: {  transform: 'translate3d(0, 50px, 0)'  },
    to: {  transform: 'translate3d(0, 0, 0)'  },
  })*/
   
/* console.log(data)*/


const Wrapper = styled.div  `
margin:0 auto;
padding:auto 3.2rem;
z-index:0;
color:white;
`


  return (
  <Wrapper>
    <SliderDefault slides={data}/>

	
		  { /*data.map((testimonial,i) => {         
                return (
                  <TestimonialCard  
                    key={i} 
                    author={testimonial.nom} 
                    comment={testimonial.contenu}              
                  />
                 )     
              }) */
            }
		

</Wrapper>
  )

}

export default TestimonialList
