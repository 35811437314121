import React from 'react'
import styled from 'styled-components'
import { colors, mq, space, font } from '../consts/style';
import  config  from '../config/SiteConfig'


const CardWrapper = styled.div`
  max-width:42.6rem;
  margin:0 auto;
  margin-bottom:3.2rem;
  color:${colors.text2};
 /* &:last-child {
    margin-bottom:0;
  }*/

`;

const Card = styled.div`
 
  display: flex;
  align-items: flex-start;
  flex-direction:column;
  padding:3.2rem;
  margin-bottom:0.8rem;
  border-radius:.8rem;
  justify-content: center;
  background:${colors.green};
  color:${colors.text2};
  &:last-child {
    margin-bottom:0;
  }

`;

const Tarif = styled.span`
text-align:right;
width:100%;
display: inline-block;
`
const Title = styled.span`
 font-weight:700;
 margin-bottom:1.6rem;
 text-align:left;
 color:${colors.dark};
 &:before {
  content:'';
  display:inline-block;
  width:2.6rem;
  height:1px;
  background:${colors.dark};
  margin-right: 0.8rem;
  transform:translateY(-5px);
 }
`;

const Content = styled.div`
text-align:justify;
      p {
        padding-bottom:1.6rem;
      }
      ul {
        list-style:disc;
        margin-left: 3.2rem;
        padding-bottom: 3.2rem;
        li:not(:last-child) {
          padding-bottom:1.6rem;
        }
      }
`;

const Encart = ({data }) => {
  
 

  return (
    <CardWrapper>
      <Card> 
        <Title>{data.titre}</Title>
        <Content dangerouslySetInnerHTML={{ __html: data.contenu }} />
      </Card>
      {data.tarif && <Tarif>{data.tarif}</Tarif>}
    </CardWrapper>
    
  )
}

export default Encart

